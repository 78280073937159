<template>
  <v-container>
    <v-tabs v-model="tab" background-color="info" dark>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-progress-linear
      v-if="TableLoading"
      class="mt-2"
      color="info"
      indeterminate
      reverse
    ></v-progress-linear>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.tab">
        <v-container>
          <v-row justify="space-around">
            <v-col cols="12" md="6">
              <v-card class="text-center" color="info" dark max-width="500">
                <v-card-text>
                  <v-sheet color="rgba(0, 0, 0, .12)">
                    <v-sparkline
                      :value="todayReport"
                      color="rgba(255, 255, 255, .7)"
                      height="100"
                      padding="24"
                      stroke-linecap="round"
                      smooth
                    >
                      <template v-slot:label="item">
                        &#8358; {{ item.value }}
                      </template>
                    </v-sparkline>
                  </v-sheet>
                </v-card-text>

                <v-card-text>
                  <div class="text-h4 font-weight-thin">Sales Today</div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="justify-center">
                  <ReportDetails
                    :tabStatus="tabStatus"
                    :PstartDate="startDate"
                    :pEndDate="endDate"
                    pStatus="today"
                    :reportData="todayReportData"
                  />
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card class="text-center" color="primary" dark max-width="500">
                <v-card-text>
                  <v-sheet color="rgba(0, 0, 0, .12)">
                    <v-sparkline
                      :value="oneMonthReport"
                      color="rgba(255, 255, 255, .7)"
                      height="100"
                      padding="24"
                      stroke-linecap="round"
                      smooth
                    >
                      <template v-slot:label="item">
                        &#8358; {{ item.value }}
                      </template>
                    </v-sparkline>
                  </v-sheet>
                </v-card-text>

                <v-card-text>
                  <div class="text-h4 font-weight-thin">
                    Last One Month Sales
                  </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="justify-center">
                  <ReportDetails
                    :tabStatus="tabStatus"
                    pStatus="onMonth"
                    :PstartDate="PstartDate"
                    :pEndDate="endDate"
                    :reportData="oneMonthReportData"
                  />
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card class="text-center" color="black" dark max-width="500">
                <v-card-text>
                  <v-sheet color="rgba(0, 0, 0, .12)">
                    <v-sparkline
                      :value="ParcelTripsChart"
                      color="rgba(255, 255, 255, .7)"
                      height="100"
                      padding="24"
                      stroke-linecap="round"
                      smooth
                    >
                      <template v-slot:label="item">
                        km {{ item.value }}
                      </template>
                    </v-sparkline>
                  </v-sheet>
                </v-card-text>

                <v-card-text>
                  <div class="text-h4 font-weight-thin">
                    Last One Month Trips
                  </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="justify-center">
                  <TripsTransaction :ParcelTrips="GetParcelTrip" />
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card class="text-center" color="pink" dark max-width="500">
                <v-card-text>
                  <v-sheet color="rgba(0, 0, 0, .12)">
                    <v-sparkline
                      :value="WalletTransactionChart"
                      color="rgba(255, 255, 255, .7)"
                      height="100"
                      padding="24"
                      stroke-linecap="round"
                      smooth
                    >
                      <template v-slot:label="item">
                        &#8358; {{ item.value }}
                      </template>
                    </v-sparkline>
                  </v-sheet>
                </v-card-text>

                <v-card-text>
                  <div class="text-h4 font-weight-thin">Wallet Records</div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="justify-center">
                  <WalletTransaction
                    :WalletTransaction="AllWalletTransaction"
                  />
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import ReportDetails from "../components/SalesReport.vue";
import WalletTransaction from "../components/WalletTransaction.vue";
import TripsTransaction from "../components/TripsTransaction.vue";
import dayjs from "dayjs";

export default {
  components: {
    ReportDetails,
    WalletTransaction,
    TripsTransaction,
  },
  data: () => ({
    tabStatus: "single",
    PstartDate: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
    startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    tab: null,
    status: "online",
    items: [
      { tab: "Revenue", content: "OnlineCustomers" },
      { tab: "All Revenue", content: "OfflineCustomers" },
    ],
  }),
  computed: {
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    todayReport() {
      return this.$store.getters.todayReport;
    },
    oneMonthReportData() {
      return this.$store.getters.oneMonthReportData;
    },
    todayReportData() {
      return this.$store.getters.todayReportData;
    },
    oneMonthReport() {
      return this.$store.getters.oneMonthReport;
    },
    WalletTransactionChart() {
      return this.$store.getters.WalletTransactionChart;
    },
    AllWalletTransaction() {
      return this.$store.getters.AllWalletTransaction;
    },
    GetParcelTrip() {
      return this.$store.getters.GetParcelTrips;
    },
    ParcelTripsChart() {
      return this.$store.getters.ParcelTripsChart;
    },

    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    getPackageByDateRange(status) {
      let payload = {
        token: this.getToken,
        status: status,
        startDate: this.startDate,
        endDate: this.endDate,
        page: 1,
      };
      if (this.tabStatus == "single") {
        this.$store.dispatch("getPackageByDateRange", payload);
      }
      if (this.tabStatus == "all") {
        this.$store.dispatch("getAllPackageByDateRange", payload);
      }
    },
    getOneMonthDateRange(status) {
      let payload = {
        token: this.getToken,
        status: status,
        startDate: dayjs().subtract(1, "month"),
        endDate: this.endDate,
        page: 1,
      };
      if (this.tabStatus == "single") {
        this.$store.dispatch("getPackageByDateRange", payload);
      }
      if (this.tabStatus == "all") {
        this.$store.dispatch("getAllPackageByDateRange", payload);
      }
    },
    GetAllWalletTransactions() {
      let payload = {
        token: this.getToken,
        page: 1,
      };
      this.$store.dispatch("getAllWalletTransactions", payload);
    },
    GetParcelTrips() {
      let payload = {
        token: this.getToken,
        page: 1,
        startDate: dayjs().subtract(1, "month"),
        endDate: this.endDate,
      };
      this.$store.dispatch("GetParcelTrips", payload);
    },
  },
  created() {
    this.getPackageByDateRange("today");
    this.getOneMonthDateRange("onMonth");
    this.GetAllWalletTransactions();
    this.GetParcelTrips();
  },
  watch: {
    tab: function (val) {
      if (val == 1) {
        this.tabStatus = "all";
        this.getPackageByDateRange("today");
        this.getOneMonthDateRange("onMonth");
      }
      if (val == 0) {
        this.tabStatus = "single";
        this.getPackageByDateRange("today");
        this.getOneMonthDateRange("onMonth");
      }
    },
  },
};
</script>