<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn block text v-bind="attrs" v-on="on"> Go to Report </v-btn>
      </template>
      <v-card>
        <v-toolbar flat dark color="info">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Close</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-simple-table dense class="mt-7">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Description</th>
                  <th class="text-left">Amount</th>
                  <th class="text-left">Previous Balance</th>
                  <th class="text-left">Available Balance</th>
                  <th class="text-left">Customer Name</th>
                  <th class="text-left">Customer Phone</th>
                  <th class="text-left">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in WalletTransaction" :key="item._id">
                  <td>{{ item.description }}</td>
                  <td>{{ DisplayCurrency(item.amount) }}</td>
                  <td>{{ DisplayCurrency(item.previousBalance) }}</td>
                  <td>{{ DisplayCurrency(item.balance) }}</td>
                  <td v-if="item.userId">
                    {{ item.userId.firstName }} {{ item.userId.lastName }}
                  </td>
                  <td v-if="item.userId">{{ item.userId.phoneNumber }}</td>

                  <td>{{ formattedDate(item.createdAt) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="totalItems"></v-pagination>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import currencyFormatter from "currency-formatter";
import xlsx from "json-as-xlsx";
import dayjs from "dayjs";

export default {
  props: ["WalletTransaction"],
  data: () => ({
    page: 1,
    dialog: false,
  }),

  computed: {
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },

    getToken() {
      return this.$store.getters.getToken;
    },
  },

  watch: {
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          token: this.getToken,
          page: val,
        };
        this.$store.dispatch("getAllWalletTransactions", payload);
      }
    },
  },

  methods: {
    formattedDate(payload) {
      return dayjs(payload).format("YYYY/MM/DD HH:mm:ss");
    },
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
    exportXlsx() {
      var columns = [
        {
          label: "Tracking Number",
          value: (row) => row.trackingNumber,
        }, // Top level data
        {
          label: "Total Parcel",
          value: (row) => row.parcelDetails.length,
        }, // Top level data
        {
          label: "Total Weight Fees",
          value: (row) => this.DisplayCurrency(row.totalWeightFees),
        }, // Top level data
        {
          label: "Shipping Fee",
          value: (row) => this.DisplayCurrency(row.shippingFee),
        }, // Top level data
        {
          label: "Status",
          value: (row) => row.Status,
        }, // Top level data
        // Top level data
        { label: "Date", value: (row) => row.createdAt }, // Top level data
      ];

      var content = this.Report;
      if (content.length == 0) {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "No data available");
        return;
      }

      var settings = {
        sheetName: "First sheet", // The name of the sheet
        fileName: "Revenue Report", // The name of the spreadsheet
        extraLength: 3, // A bigger number means that columns should be wider
      };

      var download = true; // If true will download the xlsx file, otherwise will return a buffer

      xlsx(columns, content, settings, download);
    },
  },
};
</script>