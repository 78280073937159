<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn block text v-bind="attrs" v-on="on"> Go to Report </v-btn>
      </template>
      <v-card>
        <v-toolbar flat dark color="info">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Close</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="exportXlsx"> Download Excel </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12" sm="5">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="Start Record Date"
                    hint="Get records from this date"
                    persistent-hint
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    filled
                    shaped
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="5">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    label="End Record Date"
                    hint="To this date"
                    persistent-hint
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    filled
                    shaped
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="2">
              <div class="text-center">
                <v-btn @click="Search" block x-large color="pink" dark>
                  Search
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-simple-table dense class="mt-7">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Date</th>
                  <th class="text-left">Total Parcel</th>
                  <th class="text-left">Total WeightFees</th>
                  <th class="text-left">ShippingFee</th>
                  <th class="text-left">Request Type</th>
                  <th class="text-left">Payment Method</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in Report" :key="item._id">
                  <td>{{ formattedDate(item.createdAt) }}</td>
                  <td>{{ item.parcelDetails.length }}</td>
                  <td>{{ DisplayCurrency(item.totalWeightFees) }}</td>
                  <td>{{ DisplayCurrency(item.shippingFee) }}</td>
                  <td>{{ item.RequestType }}</td>
                  <td>{{ item.PaymentMethod }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="totalItems"></v-pagination>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import currencyFormatter from "currency-formatter";
import xlsx from "json-as-xlsx";
import dayjs from "dayjs";

export default {
  props: ["reportData", "pStatus", "PstartDate", "pEndDate", "tabStatus"],
  data: () => ({
    page: 1,
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    desserts: [],
    startDate: null,
    endDate: null,
    menu1: false,
    menu2: false,
    status: "",
  }),
  created() {
    this.startDate = this.PstartDate;
    this.endDate = this.pEndDate;
    this.status = this.pStatus;
    this.$store.commit("setReport", this.reportData);
  },
  computed: {
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    Report() {
      return this.$store.getters.Report;
    },

    getToken() {
      return this.$store.getters.getToken;
    },
  },

  watch: {
    dialog: function (val) {
      if (val == false) {
        this.status = this.pStatus;
        this.$store.commit("setReport", []);
      } else {
        this.$store.commit("setReport", this.reportData);
      }
    },
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          token: this.getToken,
          status: this.status,
          startDate: this.startDate,
          endDate: this.endDate,
          page: this.page,
        };
        if (this.tabStatus == "single") {
          this.$store.dispatch("getPackageByDateRange", payload);
        }
        if (this.tabStatus == "all") {
          this.$store.dispatch("getAllPackageByDateRange", payload);
        }
      }
    },
  },

  methods: {
    formattedDate(payload) {
      return dayjs(payload).format("DD/MM/YYYY HH:mm:ss");
    },
    Search() {
      this.Currpage = 1;
      this.page = 1;
      this.status = "others";
      let payload = {
        token: this.getToken,
        status: "others",
        startDate: this.startDate,
        endDate: this.endDate,
        page: this.page,
      };
      if (this.tabStatus == "single") {
        this.$store.dispatch("getPackageByDateRange", payload);
      }
      if (this.tabStatus == "all") {
        this.$store.dispatch("getAllPackageByDateRange", payload);
      }
    },
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
    exportXlsx() {
      var columns = [
        {
          label: "Tracking Number",
          value: (row) => row.trackingNumber,
        }, // Top level data
        {
          label: "Total Parcel",
          value: (row) => row.parcelDetails.length,
        }, // Top level data
        {
          label: "Total Weight Fees",
          value: (row) => this.DisplayCurrency(row.totalWeightFees),
        }, // Top level data
        {
          label: "Shipping Fee",
          value: (row) => this.DisplayCurrency(row.shippingFee),
        }, // Top level data
        {
          label: "Status",
          value: (row) => row.Status,
        }, // Top level data
        // Top level data
        { label: "Date", value: (row) => row.createdAt }, // Top level data
      ];

      var content = this.Report;
      if (content.length == 0) {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "No data available");
        return;
      }

      var settings = {
        sheetName: "First sheet", // The name of the sheet
        fileName: "Revenue Report", // The name of the spreadsheet
        extraLength: 3, // A bigger number means that columns should be wider
      };

      var download = true; // If true will download the xlsx file, otherwise will return a buffer

      xlsx(columns, content, settings, download);
    },
  },
};
</script>