var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" Go to Report ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"info"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Close")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.exportXlsx}},[_vm._v(" Download Excel ")])],1)],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Record Date","hint":"Get records from this date","persistent-hint":"","append-icon":"mdi-calendar","readonly":"","filled":"","shaped":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Record Date","hint":"To this date","persistent-hint":"","append-icon":"mdi-calendar","readonly":"","filled":"","shaped":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"block":"","x-large":"","color":"pink","dark":""},on:{"click":_vm.Search}},[_vm._v(" Search ")])],1)])],1),_c('v-simple-table',{staticClass:"mt-7",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Date")]),_c('th',{staticClass:"text-left"},[_vm._v("Total Parcel")]),_c('th',{staticClass:"text-left"},[_vm._v("Total WeightFees")]),_c('th',{staticClass:"text-left"},[_vm._v("ShippingFee")]),_c('th',{staticClass:"text-left"},[_vm._v("Request Type")]),_c('th',{staticClass:"text-left"},[_vm._v("Payment Method")])])]),_c('tbody',_vm._l((_vm.Report),function(item){return _c('tr',{key:item._id},[_c('td',[_vm._v(_vm._s(_vm.formattedDate(item.createdAt)))]),_c('td',[_vm._v(_vm._s(item.parcelDetails.length))]),_c('td',[_vm._v(_vm._s(_vm.DisplayCurrency(item.totalWeightFees)))]),_c('td',[_vm._v(_vm._s(_vm.DisplayCurrency(item.shippingFee)))]),_c('td',[_vm._v(_vm._s(item.RequestType))]),_c('td',[_vm._v(_vm._s(item.PaymentMethod))])])}),0)]},proxy:true}])})],1),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.totalItems},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }